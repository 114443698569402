import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import router from "@/router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  homePage: string;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  errorCode = 0;
  homePage = "dashboard";
  timeout = null;
  user = {} as User;
  isIdle = false;
  permissions = [];
  isAuthenticated = !!JwtService.getToken();
  idleVue = { isIdle: false };

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Get current user object
   * @returns Boolean
   */
  get getIsIdle(): boolean {
    return this.isIdle;
  }
  /**
   *
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get getPermissions(): Array<string> {
    return this.permissions;
  }

  get getHomePage(): string {
    return this.homePage;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  get getErrorCode(): number {
    return this.errorCode;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error.errors;
    this.errorCode = error.errorCode;
  }

  @Mutation
  [Mutations.SET_AUTH](data) {
    this.isAuthenticated = true;
    this.user = data.user;
    this.homePage = data.homePage;
    this.permissions = data.permissions;
    this.errors = [];
    if (data.token) {
      JwtService.saveToken(data.token);
    }
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.IDLE_VUE_CHANGED](isIdle) {
    this.isIdle = isIdle;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("auth", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          /** Borramos los filtros que se hayan guardado **/
          localStorage.setItem("donorFilters", "");
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, {
            errors: response.data.errors,
            errorCode: response.status,
          });
          reject();
        });
    });
  }

  @Action
  [Actions.UPDATE_PASSWORD](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("auth", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, {
            errors: response.data.errors,
            errorCode: response.status,
          });
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
    router.push("/login");
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get("auth")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.dispatch(Actions.LOGOUT);
          this.context.commit(Mutations.SET_ERROR, "");
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
